import egg1 from "../assets/eggs/egg1.png";
import egg2 from "../assets/eggs/egg2.png";
import egg3 from "../assets/eggs/egg3.png";
import egg4 from "../assets/eggs/egg4.png";
import egg5 from "../assets/eggs/egg5.png";
import egg6 from "../assets/eggs/egg6.png";
import egg7 from "../assets/eggs/egg7.png";
import egg8 from "../assets/eggs/egg8.png";
import egg9 from "../assets/eggs/egg9.png";
import egg10 from "../assets/eggs/egg10.png";

export const eggsArray = [
  {
    id: 0,
    image: egg1,
  },
  {
    id: 1,
    image: egg2,
  },
  {
    id: 2,
    image: egg3,
  },
  {
    id: 3,
    image: egg4,
  },
  {
    id: 4,
    image: egg5,
  },
  {
    id: 5,
    image: egg6,
  },
  {
    id: 6,
    image: egg7,
  },
  {
    id: 7,
    image: egg8,
  },
  {
    id: 8,
    image: egg9,
  },
  {
    id: 9,
    image: egg10,
  },
];
